@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/** override html font to use google poppins font */

html {
    height: 100%;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

body {
    height: 100%;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

main {
    min-height: 100vh;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
}

.input-control {
    /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
    padding: 16px 30px;
    position: static;
    width: 100%;
    height: 54px;
    /* background: #F9F9F9; */
    ;
    /* Border */
    border: 1px solid rgba(77, 77, 77, 0.5);
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px;
    /* font-size: large; */
}

.select-control {
    padding-left: 20px;
    position: static;
    width: 100%;
    height: 54px;
    border: 1px solid rgba(77, 77, 77, 0.5);
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px;
}

.file-input-control {
    /* padding: 16px 20px; */
    position: static;
    width: 100%;
    /* height: 54px; */
    border: 1px solid rgba(77, 77, 77, 0.5);
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px;
    /* font-size: large; */
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.input-control :active {
    /* display: flex;
    flex-direction: row;
    align-items: flex-start; */
    padding: 16px 30px;
    position: static;
    width: 100%;
    height: 54px;
    /* background: #F9F9F9; */
    ;
    /* Border */
    border: 1px solid red;
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px;
    font-size: large;
}

.input-control::placeholder {
    /* font-size: large; */
    font-weight: 100;
}

.primary-button {
    background: #28367C;
    flex: none;
    /* margin-right: 32px; */
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
}

.secondary-primary-button {
    background: #28367C;
    flex: none;
    margin-right: 0px;
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 45px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    margin-top: 10px;
}


.link-button {
    background: none;
    flex: none;
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
}

.primary-button-outline {
    height: 46px;
    background: #fff;
    flex: none;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    border: 2px solid #28367C;
}

.primary-button-transparent {
    height: 46px;
    background: transparent;
    flex: none;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    border: 2px solid white;
}

.primary-button-outline-max {
    background: #fff;
    flex: none;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    width: 100%;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    border: 2px solid #28367C;
}

.primary-button-max {
    background: #28367C;
    flex: none;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    width: 100%;
    /* identical to box height */
    text-align: center;
    color: #fff;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
}

.header-description {
    color: rgb(92, 92, 92);
}

.text-main {
    color: #28367C;
}

.text-gray {
    color: rgb(92, 92, 92);
}

.kids-section {
    margin-top: 5rem;
    margin-bottom: 30px;
}

.kids-section-column {
    width: 13rem;
    margin-top: 10px;
}

.single-kids-section-column {
    width: 17rem;
    margin-top: 10px;
}

.kid-image {
    height: 240px;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 17rem;
    margin-bottom: 10px;
}

.single-kid-image {
    height: 100%;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 18rem;
    margin-bottom: 10px;
}

.kid-name {
    font-size: 20px;
    margin-bottom: 0.5rem;
    color: #28367C;
    font-weight: 500;
}

.kid-address {
    font-size: 15px;
    font-weight: 400;
}

.footer-container {
    padding: 80px 60px;
    background: #28367C;
    width: 100%;
    margin-top: auto;
}

.range-slider {
    /* margin-top: 30px; */
    width: 295px;
}

.range-slider::-webkit-slider-thumb {
    background: #233F5E;
}

.header-title {
    /* color: white; */
    font-weight: bold;
    font-size: 55px;
}

.article-header-title {
    color: #28367C;
    font-weight: bold;
    font-size: 35px;
}

.title {
    color: #28367C;
    font-weight: bold;
    font-size: 45px;
}

.sub-title {
    color: #28367C;
    font-weight: bold;
    font-size: 25px;
}

.description {
    color: rgb(92, 92, 92);
    font-size: 15px;
}

.description-white {
    color: white;
    font-size: 15px;
}

.custom-input {
    font-size: small;
    border-radius: 100px;
}

.page-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    position: static;
    height: 37px;
    right: 64px;
    top: 0px;
    background: #28367C;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    margin-top: 20px;
    /* margin-bottom: 20px; */
}

.page-link-outline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    position: static;
    height: 50px;
    right: 64px;
    top: 0px;
    background: white;
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin-left: 32px; */
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border-radius: 100px;
    border: 2px solid #28367C;
    /* margin-bottom: 20px; */
}

.link {
    font-weight: 400px !important;
}

.news-featured-section {
    width: 25rem;
    margin-bottom: 10px;
}

.news-featured-image {
    height: 95%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    /* width: 18rem; */
    margin-bottom: 10px;
}

.news-featured-section-small {
    width: 15rem;
}

.news-featured-image-small {
    height: 135px;
    border-radius: 5px;
    max-width: 100%;
    max-height: 135px;
    object-fit: cover;
    /* width: 18rem; */
    margin-bottom: 10px;
}

.news-date {
    font-size: 13px;
    margin-bottom: 0.5rem;
}

.news-title-small {
    font-size: 17px;
}

.news-date-small {
    font-size: 13px;
    margin-top: -1.0rem;
    margin-bottom: 1rem;
}

.news-description-small {
    font-size: 17px;
    color: #28367C;
    font-weight: 500;
}

.news-description-extra-small {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.news-section-column {
    width: 13rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.news-image {
    height: 100%;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 18rem;
    margin-bottom: 10px;
}

.news-title {
    font-size: 17px;
    color: rgb(92, 92, 92);
    font-weight: 500;
}

.news-description {
    font-size: 17px;
    color: #28367C;
    font-weight: 500;
}


/* Bottom right text */

.bottom-right {
    position: absolute;
    bottom: 1.3rem;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 16px;
    color: white;
    font-size: 20px;
    background-color: rgba(40, 54, 124, 0.808);
    width: 100%;
}


/* Top left text */

.top-left {
    position: absolute;
    top: 0px;
    color: white;
    background-color: #E92C24;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
}

.missing-status-tag {
    position: absolute;
    top: 0.5rem;
    color: white;
    background-color: #E92C24;
    padding: 10px;
    font-size: 15px;
    border-radius: 100px;
    right: 0.5rem;
}

.found-status-tag {
    position: absolute;
    top: 0.5rem;
    color: white;
    background-color: green;
    padding: 10px;
    font-size: 15px;
    border-radius: 100px;
    right: 0.5rem;
}

.unknown-status-tag {
    position: absolute;
    top: 0.5rem;
    color: white;
    background-color: grey;
    padding: 10px;
    font-size: 15px;
    border-radius: 100px;
    right: 0.5rem;
}

.support-featured-image {
    height: 95%;
    border-radius: 20px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    /* width: 18rem; */
    margin-bottom: 10px;
}

.primary-button-white {
    background: #fff;
    flex: none;
    margin-right: 32px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
}

.sub-title-small {
    color: #28367C;
    font-weight: bold;
    font-size: 20px;
}

.primary-button-link {
    background: #28367C;
    flex: none;
    /* margin-right: 32px; */
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    border: transparent;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #28367C;
}

.primary-button-link:hover {
    color: #fff;
}

.primary-button-transparent-link {
    background: transparent;
    flex: none;
    /* margin-right: 32px; */
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid white;
}

.primary-button-transparent-link:hover {
    background-color: #28367C;
    color: #fff;
    border: none;
}

.input-label {
    position: static;
    width: 355px;
    height: 32px;
    left: 0px;
    top: 0px;
    /* p1 bold */
    font-family: 'Arial';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 32px */
    /* Navy Blue */
    color: #28367C;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    /* margin-bottom: 16px; */
}

.textarea-control {
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    resize: auto;
    cursor: text;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    column-count: initial !important;
    margin: 0em;
    border-width: 1px;
    border-style: solid;
    border: 1px solid rgba(77, 77, 77, 0.5);
    border-image: initial;
    padding: 20px;
    width: 100%;
    border-radius: 15px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgba(41, 52, 114, 0.8);
    outline: 0 none;
}

.contact-card {
    margin-right: auto !important;
    height: 200px;
    width: 100% !important;
    background-color: #28367C;
    border-radius: 20px;
    padding-right: 30px;
    padding-left: 30px;
    color: #ffffff;
}

.contact-card-outline {
    margin-right: auto !important;
    height: 200px;
    width: 100% !important;
    border-radius: 20px;
    border: 2px solid #28367C;
    padding-right: 30px;
    padding-left: 30px;
    color: #28367C;
}

.contact-card-title {
    font-size: 23px;
    font-weight: 500;
}

.contact-card-description {
    font-size: 15px;
}

.nav-link :active {
    color: #E92C24;
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: rgba(165, 1, 1, 0.55);
}

.footer-icon {
    margin-left: 5px;
    margin-right: 5px;
}

.small-text {
    font-size: 14px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        /* width: 80px; */
        -webkit-appearance: none;
        /* background-color: #28367C; */
        border-radius: 100px;
    }

    input[type='range']::-webkit-slider-runnable-track {
        height: 12px;
        -webkit-appearance: none;
        color: #28367C;
        /* margin-top: -1px; */
        background-color: #28367C;
    }

    input[type='range']::-webkit-slider-thumb {
        width: 20px;
        -webkit-appearance: none;
        height: 20px;
        cursor: ew-resize;
        background: white;
        border-radius: 2px solid #28367C;
        box-shadow: 0 0 0 2px #28367C;
    }
}


/** FF*/

input[type="range"]::-moz-range-progress {
    background-color: #28367C;
}

input[type="range"]::-moz-range-track {
    background-color: #28367C;
}


/* IE*/

input[type="range"]::-ms-fill-lower {
    background-color: #28367C;
}

input[type="range"]::-ms-fill-upper {
    background-color: #28367C;
}

.primary-button-unknown {
    background: #28367C;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: white;
    text-decoration: none;
    border: transparent;
    border-radius: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 10px;
}

.primary-button-unknown-outline {
    background: #fff;
    /* font-weight: 700; */
    font-size: 15px;
    line-height: 21px;
    height: 54px;
    /* identical to box height */
    text-align: center;
    color: #28367C;
    text-decoration: none;
    border: transparent;
    border-radius: 100px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 10px;
    border: 2px solid #28367C;
}

.primary-button-unknown:hover {
    background: #E92C24;
    color: white;
}

.text-dl {
    font-size: 15px;
    line-height: 21px;
    font-weight: 700;
    /* identical to box height */
    color: rgb(92, 92, 92);
}

.text-dt {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    /* identical to box height */
    color: rgb(92, 92, 92);
}

.error-text {
    color: #E92C24;
    padding-top: 10px;
    /* padding-left: 10px; */
}

.css-13cymwt-control {
    padding-left: 20px;
    position: static;
    width: 100%;
    height: 54px;
    border: 1px solid rgba(77, 77, 77, 0.5) !important;
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px !important;
    font-size: large;
}

.css-t3ipsp-control {
    padding-left: 20px;
    position: static;
    width: 100%;
    height: 54px;
    border: 1px solid rgba(77, 77, 77, 0.5) !important;
    box-sizing: border-box;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 16px;
    /* margin-bottom: 0.125rem; */
    border-radius: 100px !important;
    font-size: large;
}

.PhoneInputInput {
    border: none;
}

.link-text {
    color: #28367C;
    font-size: 15px;
    cursor: pointer;
}


.cropper {
    position: relative;
    height: 35vh;
}

.viewer {
    /* height: 30vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
}

.output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
}

.nav-link {
    color: #28367C;
}

.dropdown>.dropdown-toggle {
    color: #28367C !important;
    font-weight: '600';
    font-size: 18;
}

.custom-nav-pills {
    display: flex;
    flex: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-radius: 100px !important;
    background-color: #f1f1f1;
}

.custom-nav-item {
    flex: 1;
}

.custom-nav-link {
    display: block;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    text-align: center;
    text-decoration: none;
    color: #5C5C5C;
    font-size: 15px;
}

.custom-nav-link:hover {
    background-color: #f1f1f1;
    border-radius: 100px;
    cursor: pointer;
}

.custom-nav-link.active {
    background-color: #28367C;
    color: #ffffff;
    border-radius: 100px;
    cursor: pointer;
}

.my-col {
    padding: 0 30px;
}

.list-group-item {
    border-bottom: 1px solid #F0EDEB !important;
    padding: 0;
}

/* Comment container */
.comment {
    margin-bottom: 20px;
    display: flex;
}

.comment-section-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 18px;
}

/* Comment header */
.comment-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.comment-header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-header h4 {
    margin: 0;
    font-size: 16px;
}

.comment-header span {
    font-size: 14px;
    color: #999;
    margin-left: 10px;
}

/* Comment body */
.comment-body {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 10px;
}

/* Comment footer */
.comment-footer {
    display: flex;
    align-items: center;
}

.comment-date {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
}

.comment-user {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
}

.footer-link {
    text-decoration: none;
    color: #ffffff;
}

.footer-link:hover {
    text-decoration: none;
    color: #E92C24;
}

.text-gray-small {
    font-size: 15px;
    color: rgb(92, 92, 92);
}

.remove-under-line {
    text-decoration: none;
}

.text-primary {
    color: #28367C !important;
}

.article-header-image {
    height: 500px;
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    margin-bottom: 10px;

}


@media (max-width: 992px) {

    .navbar-collapse.show {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background-color: #fff;
        margin-top: 80px;
        background-color: #ffffff;
        width: 100%;
        height: 100%;
        /* use container dimensions for padding */
        overflow-y: auto;
        /* Scrollable contents if viewport is shorter than content. */
        -webkit-overflow-scrolling: touch;
        /* for momentum scrolling on iOS devices */
        transition: transform 0.3s ease-in-out;
        /* make padding equal to container dimensions */
        padding-left: 5vw;
        padding-right: 5vw;
    }

    @media (max-width: 768px) {
        .navbar-collapse.show {
            padding-left: 5vw;
            padding-right: 5vw;
        }
    }

    @media (min-width: 768px) {
        .navbar-collapse.show {
            padding-left: 7vw;
            padding-right: 7vw;
        }
    }

    @media (min-width: 992px) {
        .navbar-collapse.show {
            padding-left: 10vw;
            padding-right: 10vw;
        }
    }

    .navbar-collapse {
        background-color: #ffffff;
        padding: 20px;
        width: 100%;
        height: 100%;
    }

    .navbar-nav-scroll {
        overflow-y: hidden !important;
        max-height: 500px !important;
    }

    .dropdown-item {
        color: #28367C !important;
    }

    .dropdown-divider {
        border-top: 1px solid #28367C !important;
    }

}